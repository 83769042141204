@import "assets/v2/css/bootstrap/functions";
@import "assets/v2/css/bootstrap/variables";
@import "assets/v2/css/bootstrap/mixins";
@import "assets/v2/css/variables";

//html {
//  font-size: 1rem;
//}
//
//@include media-breakpoint-up(sm) {
//  html {
//    font-size: 1.1rem;
//  }
//}
//
//@include media-breakpoint-up(md) {
//  html {
//    font-size: 1.2rem;
//  }
//}
//
//@include media-breakpoint-up(lg) {
//  html {
//    font-size: 1.3rem;
//  }
//}

$font-family-base: "Montserrat", "Source Sans Pro";
@import "assets/v2/css/bootstrap/bootstrap";

.navbar {
  padding: 1rem 0;
}

a.nav-link {
  color: $phplist;
  font-family: $font-text;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.nav-item.active a.nav-link {
  color: $phplist;
  font-weight: 700;
}

.mauticform-label {
  font-size: 1rem !important;
}

.mauticform-button-wrapper .mauticform-button,
.mauticform-pagebreak-wrapper .mauticform-pagebreak {
  color: $white !important;
  font-size: 14px !important;
  font-family: $font !important;
  font-weight: 600 !important;
  border: none !important;
  background-color: $blue !important;
  box-shadow: 0 3px 6px #00000029 !important;
  padding: 10px 24px !important;
  border-radius: 25px !important;

  &:hover {
    box-shadow: 0 3px 30px #00000029 !important;
  }
}

li.nav-item a.nav-link.register {
  background: $blue 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: $white;
  padding: 9px 25px;
}

li.nav-item a.nav-link.register.bluenav {
  background: $white 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: $blue !important;
  padding: 9px 25px;
}

li.nav-item a.nav-link.register:hover {
  background: #2068af;
  color: $white;
}

li.nav-item a.nav-link:last-child {
  content: "|";
}

.langItems a {
  color: #2699fb;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}

.simple-page {
  padding: 20px;
  margin-block-start: 50px;
}

p.simple {
  font-family: $font-text;
  font-size: 13px;
}

ul.simple {
  font-family: $font-text;
  font-size: 13px;
}

.blue-bg {
  background: #2e73ba 0 0 no-repeat padding-box;
  opacity: 1;
}

select.discount {
  background: $white;
  color: $phplist;
  font-family: $font-text;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid $phplist;
  padding: 3px 10px;
}

select.discount-mobile {
  background: $white;
  color: $phplist;
  box-shadow: 0 3px 6px #00000029;
  font-family: $font-text;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px 20px;
}

select.discount-mobile-2 {
  background: $white;
  color: $phplist;
  box-shadow: 0 3px 6px #00000029;
  font-family: $font-text;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 20px;
}

select.langMenu {
  background: transparent;
  border: none;
  color: $phplist;
}

.bg-gradient-primary {
  background-image: url("/assets/v2/img/header_background.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.background-white {
  background-color: $white;
}

.background-blue {
  background-color: $blue;
}

.background-grey {
  background-color: rgba(112, 112, 112, 0.1);
}

.background-grey-2 {
  background-color: #f9f9f9;
}

.background-grey-light {
  background-color: rgba(243, 243, 243, 0.5);
}

.big-header {
  font-family: $font;
  font-weight: 400;
  letter-spacing: 0.42px;
  font-size: 52px;
  color: $footer;
}

.header-text {
  font-family: $font-text;
  font-weight: 400;
  font-size: 22px;
  color: $copyright;
  margin-top: 30px;
}

.header-content {
  font-family: $font;
  font-weight: bold;
  font-size: 32px;
  color: $phplist;
  letter-spacing: 0;
}

.header-section {
  font-family: $font;
  font-weight: 600;
  font-size: 16px;
  color: $phplist;
}

.small-header-section {
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  color: $phplist;
}

.big-header-section {
  font-family: $font;
  font-weight: 600;
  font-size: 24px;
  color: $phplist;
}

.green {
  color: $green !important;
}

.blue {
  color: $blue !important;
}

.orange {
  color: $orange !important;
}

.grey {
  color: $grey !important;
}

.border-top-blue {
  border-top: 2px solid $blue;
}

.link-arrow {
  font-family: $font;
  font-weight: 600;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

a.btn.btn-green.rounded-pill {
  color: $white;
  background: $green;
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0 3px 35px #00000029;
  border-radius: 40px;
  padding: 16px 45px;
}

a.btn.btn-green.rounded-pill:hover {
  box-shadow: 0 3px 8px #00000029;
}

a.btn.btn-white.rounded-pill {
  color: $blue;
  background: $white;
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0 3px 35px #00000029;
  border-radius: 40px;
  padding: 16px 45px;
  width: unset;
}

a.btn.btn-white {
  color: $blue;
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  background-color: $white;
  padding: 16px;
  border-radius: 25px;
  width: 100%;
}

a.btn.btn-orange {
  color: $white;
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  background-color: $orange;
  padding: 16px;
  border-radius: 30px;
  width: 100%;
}

a.btn.btn-orange-2 {
  color: $white;
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  background-color: $orange;
  padding: 16px;
  border-radius: 30px;
  box-shadow: 0 3px 6px #00000029;

  &:hover {
    box-shadow: 0 3px 30px #00000029;
  }
}

a.btn.btn-transparent {
  color: $white;
  background-color: transparent;
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  padding: 16px 45px;
  border: 2px solid $white;
  border-radius: 28px;
}

a.btn.btn-blue {
  color: $white;
  font-size: 14px;
  font-family: $font;
  font-weight: 600;
  border: none;
  background-color: $blue;
  box-shadow: 0 3px 6px #00000029;
  padding: 16px 40px;
  border-radius: 25px;

  &:hover {
    box-shadow: 0 3px 30px #00000029;
  }
}

a.btn.btn-outline-custom {
  border: 2px solid $blue;
  border-radius: 28px;
  color: $blue;
  padding: 16px 40px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.1px;
  opacity: 1;

  &:hover {
    color: $white;
    background-color: $blue;
    box-shadow: 0 3px 30px #00000029;
  }
}

span.freeConsultation {
  text-align: left;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0.1px;
  color: $blue;
  text-transform: initial;
  font-weight: 600;
}

span.bookDemo {
  text-align: left;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0.1px;
  color: $white;
  text-transform: initial;
  font-weight: 600;
}

span.getStartedForFree {
  text-align: left;
  font-family: Montserrat;
  letter-spacing: 0.1px;
  color: $white;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
}

span.getFreeConsultation {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: $white;
  text-transform: initial;
  font-family: Montserrat;
  font-weight: 600;
}

span.bookDemoNow {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: $blue;
  text-transform: initial;
  font-family: Montserrat;
  font-weight: 600;
}

i.fas.fa-arrow-right {
  color: $white;
}

.item:hover i.fas.fa-arrow-right {
  color: #2e72ba;
}

.top-buffer {
  margin-top: 90px;
}

.top-buffer-btw-sections {
  margin-top: 120px;
}

.top-buffer-100 {
  margin-top: 100px;
}

.top-buffer-110 {
  margin-bottom: 110px;
}

.top-buffer-91 {
  margin-top: 91px;
}

.top-buffer-65 {
  margin-top: 65px;
}

a.btn.btn-light.custom-btn {
  color: #212529;
  background-color: #f4f6f7;
  border-color: #f8f9fa;
  padding: 13px 18px;
  border-radius: 30px;
  margin-right: 30px;
}

#features.a:active {
  background: transparent linear-gradient(125deg, #f2673f 0%, #f9b29e 100%) 0 0
    no-repeat padding-box;
  box-shadow: 0 3px 25px #00000026;
  border-radius: 50px;
  color: $white;
  box-shadow: 0 3px 25px;
}

a.btn.btn-light.custom-btn:focus {
  background: transparent linear-gradient(125deg, #f2673f 0%, #f9b29e 100%) 0 0
    no-repeat padding-box;
  box-shadow: 0 3px 25px #00000026;
  border-radius: 50px;
  color: $white;
  box-shadow: 0 3px 25px;
}

.square {
  width: 81px;
  height: 81px;
  background: $blue 0% 0% no-repeat padding-box;
  border-radius: 10px;
  position: absolute;
}

img.deliverability {
  text-align: center;
}

.thumbnail_container {
  width: 25%;
  background: $blue 0% 0% no-repeat padding-box;
  border-radius: 10px;
  float: left;
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}

.thumbnail img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

p.text-dark.text-left {
  font-family: $font-text;
  font-weight: 300;
  line-height: 20px;
  font-size: 16px;
}

h3.card-title {
  text-align: left;
  font-family: $font;
  letter-spacing: 0.13px;
  color: $phplist;
  font-size: 18px;
  font-weight: 600;
}

.card-title.custom {
  text-align: left;
  font-family: $font;
  font-size: 18px;
  letter-spacing: 0.13px;
  color: $phplist;
  text-transform: uppercase;
  font-weight: 700;
}

p.text-muted pb-3 {
  text-align: left;
  font-family: $font-text;
  font-weight: 300;
  font-size: 16px;
}

img.img-fluid.whitetooltip + .tooltip > .tooltip-inner {
  background-color: $white;
}

img.img-fluid-2 {
  max-width: 100%;
  height: auto;
  background-color: white;
  border-radius: 10%;
  padding: 5px;
  margin-bottom: 0;
  margin-top: 5px;
}

.top-buffer-200 {
  margin-top: 200px;
}

.top-buffer-136 {
  margin-top: 136px;
}

.pricing-box {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 80px;
  height: 80px;
  line-height: 90px;
  margin: 40px auto;
  position: relative;
  margin-bottom: 40px;
  background: $white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 10%;
}

.features-small-item {
  margin-bottom: 40px;
}

.features-title {
  text-align: center;
  letter-spacing: 0.13px;
  color: $white;
  font-family: $font;
  font-weight: 600;
  font-size: 18px;
  font-weight: 600;
}

p.pricing-description {
  text-align: center;
  font-family: "Source Sans Pro", lighter;
  color: $white;
  font-size: 14px;
}

.top-buffer-250 {
  margin-bottom: 250px;
}
//#wave {
//  position: relative;
//  height: 70px;
//  background: $blue 0% 0% no-repeat padding-box;
//}
//
//#wave:before {
//  content: "";
//  display: block;
//  position: absolute;
//  border-radius: 100% 50%;
//  width: 50%;
//  height: 80px;
//  background-color: white;
//  right: -5px;
//  top: 40px;
//}
//
//#wave:after {
//  content: "";
//  display: block;
//  position: absolute;
//  border-radius: 100% 50%;
//  width: 50%;
//  height: 70px;
//  background-color: white;
//  left: 0;
//  top: 27px;
//}

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}
@media only screen and (max-width: 760px) {
  .nospace {
    margin-top: -180px !important;
  }
}

.mt-180 {
  margin-top: 180px;
}

.mt-173 {
  margin-top: 173px !important;
}

.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.mb-138 {
  margin-bottom: 139px;
}

.m-negative {
  padding-top: 8px;
  margin-top: -8px;
}

.px-2 {
  padding-left: ($spacer * 0.5) !important;
  padding-right: ($spacer * 0.5) !important;
}

.p-3 {
  padding: $spacer !important;
}

.pr-6 {
  padding-left: 170px;
}

.pl-6 {
  padding-right: 170px;
}

.mb-256 {
  margin-bottom: 256px;
}

.pb-6 {
  padding-bottom: 5 $spacer !important;
}

.btn.btn-transparent.bg {
  background: $white 0% 0% no-repeat padding-box;
  border: 2px solid $green;
  border-radius: 28px;
  width: 257px;
  font-size: 14px;
}

span.findOutMore {
  text-align: left;
  font-family: "Montserrat";
  color: $green;
  text-transform: initial;
  font-weight: 700;
}

h4.gdpr {
  text-align: left;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.13px;
  color: $white;
}

a.btn.btn-transparent.custom {
  float: right;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  border-radius: 40px;
  background: $white;

  &:hover {
    border: 2px solid $white;
    color: $white !important;
    background: transparent;
  }
}

#howwegetresults .text-muted {
  color: $phplist !important;
}

.testimonial {
  /* The image used */
  background-image: url("/assets/v2/img/testimonial_1.jpg");
  background: transparent
    linear-gradient(
      91deg,
      $white 0%,
      #fffffffd 36%,
      #fffffff0 65%,
      #ffffffdc 73%,
      #ffffff00 100%
    )
    0 0 no-repeat padding-box;
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial2 {
  /* The image used */
  background-image: url("/assets/v2/img/testimonial_2.jpg") !important;
  background: transparent
    linear-gradient(
      91deg,
      $white 0%,
      #fffffffd 36%,
      #fffffff0 65%,
      #ffffffdc 73%,
      #ffffff00 100%
    )
    0 0 no-repeat padding-box;
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial3 {
  /* The image used */
  background-image: url("/assets/v2/img/testimonial_3.jpg") !important;
  background: transparent
    linear-gradient(
      91deg,
      $white 0%,
      #fffffffd 36%,
      #fffffff0 65%,
      #ffffffdc 73%,
      #ffffff00 100%
    )
    0 0 no-repeat padding-box;
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $white;
  text-align: left;
}

.carousel-indicators {
  float: left;
  left: 60px;
  justify-content: left;
  position: absolute;
  right: 0;
  bottom: 220px;
  display: none;
}

ol.carousel-indicators li {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  cursor: pointer;
  background: $orange 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.card-subtitle.custom {
  text-align: left;
  font-family: $font;
  font-weight: 600;
  color: $phplist;
  font-size: 18px;
}

.carousel-indicators .active {
  background: $orange 0% 0% no-repeat padding-box;
}

.view {
  background: transparent
    linear-gradient(
      91deg,
      $white 0%,
      #fffffffd 36%,
      #fffffff0 65%,
      #ffffffdc 73%,
      #ffffff00 100%
    )
    0 0 no-repeat padding-box;
}

[data-background-color="black"] {
  background-color: $footer;
}

.home-newsletter {
  padding: 30px 0 40px;
}

.home-newsletter .single {
  position: relative;
  max-width: 330px;
  z-index: 2;
}
@media only screen and (max-width: 480px) {
  .home-newsletter .single {
    max-width: 100%;
  }

  .negative-margin {
    margin-top: -65px;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: $phplist !important;
  }
}

.home-newsletter .single h2 {
  font-size: 22px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.home-newsletter .single .form-control {
  background: transparent;
  border: 1px solid $white;
  padding: 10px 20px;
  border-radius: 20px 0 0 20px;
  font-size: 12px;
  color: $white;
}

.home-newsletter .single .form-control::placeholder {
  color: #aaa;
}

.bg-header-image {
  background-image: url("/assets/v2/img/header_photo.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-onprem-image {
  background-image: url("/assets/v2/img/onprem_cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 85% 85% / 15%;
}

.our-offers-section {
  background: transparent linear-gradient(101deg, #2e73ba 0%, #4b8cca 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.background-open {
  background-image: url("/assets/v2/img/source_code.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.background-subscriber {
  background-image: url("/assets/v2/img/pay_per_subscriber_header.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  box-shadow: 0 -20px 20px #0000001a;

  &-mobile {
    background-image: url("/assets/v2/img/pay_per_subscriber_header.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
  }
}

.background-message {
  background-image: url("/assets/v2/img/pay_per_message_header.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  box-shadow: 0 -20px 20px #0000001a;

  &-mobile {
    background-image: url("/assets/v2/img/pay_per_message_header.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
  }
}

.background-marketer {
  background-image: url("/assets/v2/img/pro_marketer_account_header.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  box-shadow: 0 -20px 20px #0000001a;

  &-mobile {
    background-image: url("/assets/v2/img/pro_marketer_account_header.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
  }
}

.background-plus {
  background-image: url("/assets/v2/img/enable_plus_header.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  box-shadow: 0 -20px 20px #0000001a;
}

.home-newsletter .single .form-control:focus {
  box-shadow: none;
  border-color: #c1dff7;
}

.home-newsletter .single .btn {
  background: $white;
  border-radius: 0 20px 20px 0;
  color: $phplist;
  text-align: center;
  font-family: $font;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0;
  width: 120px;
  height: 40px;
}

.font-12 {
  font-size: 12px !important;
}

.form-control {
  color: $white;
}

input.form-control {
  height: 40px;
}

ul.social-buttons.float-right {
  list-style-type: none;
}

.btn-link {
  color: $white;
}

a.mx-2 {
  color: $white;
}
/* Tabs*/

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

#tabs {
  background: transparent;
}

.nav-tabs {
  border-bottom: none;
  border-radius: 30px;
}

a.nav-item.nav-link.active {
  box-shadow: -10px 0 30px #00000026;
}

#tabs h6.section-title {
  color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f3f3f3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  font-size: 20px;
  font-weight: bold;
}

#tabs .nav-tabs .nav-link {
  color: #eee;
  font-size: 20px;
}

.nav {
  display: inline-flex;
}

#tabs .nav-tabs .nav-link {
  font-size: 16px;
  text-align: center;
  font-family: Montserrat;
  letter-spacing: 0.11px;
  color: $phplist;
  background: #f5f7f8 0 0 no-repeat padding-box;
  font-weight: bold;
  padding: 16px 27px;
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $white !important;
  font-size: 16px !important;
  font-weight: bold !important;
  background: $orange 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.nav-tabs {
  border-bottom: none;
}

#clients .col-sm-6 {
  text-align: center;
  padding-bottom: 40px;
  width: 50%;
}

#clients .col-sm-6.last {
  text-align: center !important;
  padding-bottom: 40px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  #clients .col-sm-6.no-text {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  col-md-5.mt-180 {
    margin-top: 30px !important;
  }
}

.bg-white-color:hover {
  background-color: $white;
  -webkit-transform: translate(-0.2rem, -0.2rem);
  transform: translate(-0.2rem, -0.2rem);
  -webkit-box-shadow: 0.55rem 0.55rem 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0.55rem 0.55rem 0 0 rgba(0, 0, 0, 0.1);
}

.bg-white-color {
  background-color: transparent;
  border-radius: 5px 0 0 5px;
  border: 1px solid $white;
}

#hover-content_1 {
  display: none;
}

#extrabtn_1:hover {
  display: block;
  moz-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  -webkit-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
}

#extrabtn_1:hover #hover-content_1 {
  display: block;
}

#hover-content_2 {
  display: none;
}

#extrabtn_2:hover {
  display: block;
  moz-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  -webkit-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
}

#extrabtn_2:hover #hover-content_2 {
  display: block;
}

#hover-content_3 {
  display: none;
}

#result {
  color: #ffffff;
  margin-top: 10px;
}

#extrabtn_3:hover {
  display: block;
  moz-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  -webkit-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
}

.freeforfoss {
  background: rgba(2, 85, 219, 0.3) 0 0 no-repeat padding-box;
  // color: #2E73BA ;
}

#extrabtn_3:hover #hover-content_3 {
  display: block;
}

#hover-content_4 {
  display: none;
}

#extrabtn_4:hover {
  display: block;
  moz-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  -webkit-box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
  box-shadow: 0 4px 0 8px $white, 3px 3px 19px 6px $white;
}

#extrabtn_4:hover #hover-content_4 {
  display: block;
}
//.features-title:hover  {
//  color:black;
//}
//.pricing-description:hover {
//  color:black;
//}

a.btn.btn-primary.pricingButton {
  background: $blue 0% 0% no-repeat padding-box;
  border: 2px solid #2699fb21;
  border-radius: 30px;
  font-family: Montserrat;
  font-weight: 500;
  letter-spacing: 0.07px;
  color: $white;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 18px;
  padding: 12px 26px;
}

.display-4 {
  font-family: $font;
  font-size: 48px;
  letter-spacing: 0.34px;
  font-weight: 600;
}
// Slider
.shadow-card {
  box-shadow: 0 -20px 19px #0000001a;
  border-radius: 20px;
}

.shadow-card-home {
  box-shadow: 0 3px 20px #00000029;
  border-radius: 20px;
}

.show-more[aria-expanded="false"]:before {
  content: "Show more features \a ▼";
  white-space: pre;
}

.show-more[aria-expanded="true"]:before {
  content: "Show less features \a ▲";
  white-space: pre;
}

.show-more-plus[aria-expanded="false"]:before {
  content: "Show all features \a ▼";
  white-space: pre;
}

.show-more-plus[aria-expanded="true"]:before {
  content: "Show less features \a ▲";
  white-space: pre;
}

.hidden {
  visibility: hidden;
}

.slider {
  width: 320px;
  text-align: center;
  overflow: hidden;
}

.slider-home {
  text-align: center;
  overflow: hidden;
}

.slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(320px);
  scroll-snap-type: mandatory;
}

.slider > a {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  background: grey;
  color: $white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0.5rem;
  position: relative;
}

.slider-home > a {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  background: grey;
  color: $white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 1.5rem 0.5rem 0 0.5rem;
  position: relative;
}

.slider-home.less-top > a {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  background: #bfbfbf;
  color: $white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0.5rem;
  position: relative;
}

.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.slides::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.slides::-webkit-scrollbar-track {
  background: transparent;
}

.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 320px;
  height: 480px;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slides img {
  object-fit: cover;
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bookdemo {
  text-align: left;
  font-family: $font;
  font-weight: 500;
  font-size: 12px;
  color: $blue;
}

.bg-gradient-blue {
  /* Location of the image */
  background-image: url("/assets/v2/img/pricing_bg.svg");
  /* Image is centered vertically and horizontally at all times */
  background-position: right bottom;
  /* Image doesn't repeat */
  background-repeat: no-repeat;
  /* Makes the image fixed in the viewport so that it doesn't move when
     the content height is greater than the image height */
  //background-attachment: fixed;
  /* This is what makes the background image rescale based on its container's size */
  background-size: cover;
  /* SHORTHAND CSS NOTATION
   * background: url(background-photo.jpg) center center cover no-repeat fixed;
   */
}

.text-small {
  font-size: 10px !important;
}

.social-buttons {
  float: right;
}
/* For mobile devices */
@media only screen and (max-width: 767px) {
  .bg-gradient-blue {
    /* The file size of this background image is 93% smaller
     * to improve page load speed on mobile internet connections */
    background-image: url("/assets/v2/img/pricing_bg.svg");
    background-position: 80%;
    background-repeat: no-repeat;
  }

  .display-4 {
    font-family: $font;
    font-size: 28px;
    text-align: center;
    letter-spacing: 0.2px;
    color: $phplist;
    line-height: 48px;
    font-weight: 600;
  }

  p.mobile.mt-3 {
    text-align: center;
  }

  #device .mt-5 {
    text-align: center !important;
  }

  .btn.btn-transparent.bg {
    background: $blue 0% 0% no-repeat padding-box;
    border-radius: 28px;
  }

  span.findOutMore {
    color: $white;
    font-weight: 600;
  }

  #gdpr-compliant h4.text-white {
    text-align: center;
    font-weight: 600;
  }

  #gdpr-compliant p.text-white {
    text-align: center;
  }

  a.btn.btn-transparent.custom {
    display: inline-block;
    width: 100%;
    margin: 0 auto 32px;
  }

  .media {
    text-align: center;
  }

  .card-title.custom {
    text-align: center;
    margin-top: 25px;
  }

  #tabs.row.mt-4 {
    margin-bottom: 30px;
  }

  .card-title {
    margin-bottom: 6px;
  }

  p.lead.ml-5.mt-4 {
    font-size: 20px !important;
    margin-left: 0 !important;
  }

  #carousel-testimonial .carousel-caption {
    right: 0;
    left: 0;
    margin-left: 0;
  }

  #carousel-testimonial .carousel-indicators {
  }

  a.btn.btn-blue.rounded-pill.mobilebtn_1 {
    margin-right: 0;
    background: $green;
  }

  a.btn.btn-outline-custom.mobilebtn_2 {
    margin-top: 40px;
    background: transparent;
    color: $phplist;
    text-transform: uppercase;
    border: none;
    font-size: 14px;
  }

  a.btn.btn-outline-custom.mobilebtn_2 i.fas.fa-arrow-right {
    color: $phplist;
    margin-left: 4px;
  }
  img.img-fluid-2 {
    max-width: 30%;
    margin-bottom: 10px;
    margin-top: 0;
  }
}

#social-icons {
  float: none;
}
@media only screen and (max-width: 991px) {
  .social-buttons {
    float: left;
  }

  li.nav-item a.nav-link.register {
    background: transparent !important;
    color: rgba(0, 0, 0, 0.5) !important;
    padding: 0.5rem 1rem !important;
  }

  a.dropdown-menu.show {
    background-color: transparent;
    border: none !important;
  }

  span.hidden-phone {
    display: none;
  }
}
/* Dropdown menu item */

.dropdown-item {
  text-align: left;
  font-family: $font;
  letter-spacing: 0;
  color: $phplist;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
}

.dropdown-item:hover {
  color: #0e1215;
  text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #0e1215;
  text-decoration: none;
  background-color: transparent;
}

a.btn.btn-outline-custom.mobilebtn_2 i.fas.fa-arrow-right {
  color: $blue;
}

p.large-screen {
  text-align: right;
  font-size: 14px;
  font-family: Source Sans Pro, Light;
  letter-spacing: 0;
  color: $phplist;
}
//.carousel-control-prev,
//.carousel-control-next {
//  align-items: flex-end;; /* Aligns it at the bottom */
//}
//
//.carousel-control-prev, .carousel-control-next {
//  width: 94%;
//}
//
//.carousel-control-prev, .carousel-control-next {
//  color: #707070;
//}

#productive-all-devices .carousel {
  box-shadow: 0 18px 35px #00000017;
}

#device .mt-5 {
  text-align: right;
}

#device .mt-4 {
  text-align: right;
}

p.lead.mt-4 {
  text-align: left;
  font-family: "Source Sans Pro";
  font-size: 30px;
  letter-spacing: 0;
  color: $phplist;
  font-style: italic;
  font-weight: 300;
}

a.mobilebtn_1 {
  margin-right: 0;
}

.footer-header {
  font-family: $font;
  font-weight: 600;
  font-size: 18px;
}

.footer-text {
  font-family: $font-text;
  font-weight: 300;
  font-size: 14px;
}

.pricing-section {
  font-family: $font;
  font-size: 16px;
  font-weight: 600;
}

.pricing-features {
  font-family: $font;
  font-size: 16px;
  font-weight: 400;
  color: $phplist;
}

.trademark {
  font-family: $font-text;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;

  &-pricing {
    font-family: $font-text;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    color: $phplist;
  }
}

.label-light {
  font-family: $font-text;
  font-size: 16px;
  font-weight: 300;
  color: $phplist;
}

.small-label-light {
  font-family: $font-text;
  font-size: 14px;
  font-weight: 300;
  color: $phplist;
}

.thinner {
  padding: 10px 24px !important;
}

.labels {
  font-family: $font;
  font-weight: 600;
  font-size: 18px;
  color: $phplist;
  letter-spacing: 0.13px;
}

.custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

progress {
  -webkit-appearance: none;
}

.pointer {
  cursor: pointer;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  background: $blue;
  cursor: pointer;
}

.custom-range::-webkit-progress-value {
  background-color: $blue;
}

.custom-range::-moz-range-progress {
  background: $blue;
}
/* ANIMATED X */

.animated-icon1,
.animated-icon2,
.animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon2 span {
  background-color: $phplist;
}
/* Icon X*/
.animated-icon2 span:nth-child(1) {
  top: 0;
}

.animated-icon2 span:nth-child(2),
.animated-icon2 span:nth-child(3) {
  top: 10px;
}

.animated-icon2 span:nth-child(4) {
  top: 20px;
}

.animated-icon2.open span:nth-child(1) {
  top: 11px;
  width: 0;
  left: 50%;
}

.animated-icon2.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
  top: 11px;
  width: 0;
  left: 50%;
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}
/*PRICING SECTION*/

.pricing-table {
  font-family: "Montserrat", sans-serif;
  padding-top: 80px;
  padding-bottom: 10px;
}

.pricing-table .block-heading {
  padding: 40px 0;
  text-align: center;
}

.pricing-table .block-heading h2 {
  color: #3b99e0;
}

.pricing-table .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.pricing-table .heading {
  text-align: center;
  padding-bottom: 16px;
}

.pricing-table .item {
  //background-color: $white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
  border: 1px solid $white;
  padding: 40px 10px;
  overflow: hidden;
  position: relative;
  min-height: 325px;
}

.pricing-table .col-md-5:not(:last-child) .item {
  margin-bottom: 30px;
  cursor: pointer;
}

.pricing-table .item button {
  font-weight: 600;
  background: $white 0% 0% no-repeat padding-box;
  border: 2px solid #2699fb21;
  border-radius: 30px;
  font-family: $font;
  font-weight: 500;
  letter-spacing: 0.07px;
  color: #2e72ba;
  text-transform: initial;
  font-size: 12px;
  line-height: 18px;
}

.pricing-table .ribbon {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: $white;
  font-weight: bold;
  box-shadow: 0 2px 3px rgba(136, 136, 136, 0.25);
  background: #4dbe3b;
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px;
}

.pricing-table .item p {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
}

.pricing-table .features .feature {
  font-weight: 600;
}

.pricing-table .features h4 {
  text-align: center;
  font-size: 18px;
  padding: 5px;
}

.pricing-table .price h4 {
  margin: 15px 0;
  font-size: 45px;
  text-align: center;
  color: #2288f9;
}

.pricing-table .buy-now button {
  text-align: center;
  margin: auto;
  font-weight: 600;
  padding: 9px 0;
}

.hidden-default {
  display: none;
}

button.btn.btn-outline-primary {
  padding: 10px 25px;
}

.borders-left .item:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.borders-right .item:first-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.item:hover {
  background-color: $white;
  transform: translateY(-40px) scale(1.025);
  transition: ease-in-out 0.2s;
  border-radius: 15px;
  box-shadow: 0 3px 50px #00000026;
  min-height: 386px;
}

.item:hover .hidden-default {
  display: block;
}

.item:hover h5.features-title {
  color: $blue;
}

.text-blue {
  color: $blue;
}

.col.box {
  margin-bottom: 48px;
}

.item:hover p.pricing-description {
  color: $phplist;
}

.button-link:hover {
  text-decoration: none;
}

.item:hover button.btn.btn-outline-primary {
  background: $blue 0% 0% no-repeat padding-box;
  border: 2px solid #2699fb21;
  color: $white;
}

.item .btn {
  display: block;
}

.item:hover span {
  color: #2e72ba;
}

.icon-background {
  color: $white;
}

.fa-envelope:before {
  content: "\f0e0";
  color: #2e72ba;
}

.fa-users:before {
  content: "\f0e0";
  color: #2e72ba;
}

.fa-desktop:before {
  content: "\f0e0";
  color: #2e72ba;
}

.fa-desktop:before {
  content: "\f0e0";
  color: #2e72ba;
}

.fa-money-bill-alt:before {
  content: "\f0e0";
  color: #2e72ba;
}

.item:hover .icon-background {
  color: #2e72ba;
}

.item:hover .fa-envelope:before {
  color: $white;
}

.item:hover .fa-users:before {
  color: $white;
}

.item:hover .fa-desktop:before {
  color: $white;
}

.item:hover .fa-money-bill-alt:before {
  color: $white;
}

.tooltip-inner {
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0;
  color: $copyright;
  background-color: $white;
  box-shadow: 0 3px 17px #00000029;
}

.tooltip-inner:before {
  background-color: $white;
  border-width: 5px 5px 0;
}

.tooltip.bottom .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: $white !important;
}

.dropdown-menu {
  left: -50% !important;
  margin: 0.35rem 0 0 !important;
  box-shadow: 0 3px 15px #00000029;
}

.dropdown-menu-arrow {
  top: -25px;
  left: 50%;
  width: 0;
  height: 0;
  position: relative;
}

.dropdown-menu-arrow:after,
.dropdown-menu-arrow:before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.dropdown-menu-arrow:after {
  bottom: -13px;
  right: -8px;
  border-bottom-color: $white;
}

.dropdown-menu-arrow:before {
  bottom: -12px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.navbar-collapse.collapse.show ul.navbar-nav.ml-auto li {
  background: #f8fcff none repeat scroll 0 0;
  float: left;
  position: relative;
  width: 100%;
  border-top: 1px solid #dae2f1;
}

.navbar-collapse {
  margin-top: 15px;
}

footer ul.list-unstyled.text-small li a {
  font-family: $font;
  font-size: 12px;
  font-weight: 600;
}

footer ul.list-unstyled.text-small li a:hover {
  color: $white;
  text-decoration: underline;
}

.testimonial {
  /* Image is centered vertically and horizontally at all times */
  background-position: right bottom;
  /* Image doesn't repeat */
  background-repeat: no-repeat;
  /* Makes the image fixed in the viewport so that it doesn't move when
     the content height is greater than the image height */
  //background-attachment: fixed;
  /* This is what makes the background image rescale based on its container's size */
  background-size: cover;
  width: 100%;
  height: 840px;
}
@media only screen and (max-width: 767px) {
  .testimonial {
    background-image: none !important;
  }
}
@media only screen and (max-width: 767px) {
  p.ontestimonial {
    font-size: 14px !important;
    width: 200px;
  }
}

.overlay {
  //position: absolute;
  top: 0;
  left: 0;
  padding: 140px 20px;
  text-align: center;
  display: block;
  width: 100%;
  top: 35%;
  background: linear-gradient(
    91deg,
    $white 0%,
    #fffffffd 36%,
    #fffffff0 48%,
    #ffffffdc 53%,
    #ffffff00 76%
  );
  height: 840px;

  .headline {
    margin-bottom: 10px;
  }

  .description {
    display: block;
    margin-bottom: 20px;
  }

  .button {
    border: 1px solid $white;
    font-weight: bold;
    color: #000;
    background: rgba(2, 85, 219, 0.3);
    padding: 10px;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;

    &:hover {
      background: rgba(2, 85, 219, 0.6);
    }
  }
}

#quotes {
  height: 85px;
  width: 85px;
  position: relative;
}

#image {
  position: absolute;
  left: 0;
  top: 0;
}

#text {
  z-index: 100;
  position: absolute;
  left: 76px;
  top: 43px;
  width: 300px;
  font-family: Montserrat;
  letter-spacing: 0.13px;
  color: $phplist;
  font-weight: 600;
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  #text {
    left: 46px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  #image {
    width: 45px;
    height: 45px;
  }
}
@media only screen and (max-width: 767px) {
  p.lead.mt-4.mb-6 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1682px) {
  img.header_img {
    max-width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 991px) {
  #customer {
    margin-bottom: 0;
  }
}
//#customer {
//margin-bottom:-19px;
//}

#footer a.btn-link:hover {
  text-decoration: none !important;
  color: $white !important;
}

.btn-link:hover {
  color: $white;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

ul.list-unstyled.text-small {
  float: right;
}
@media only screen and (max-width: 992px) {
  ul.list-unstyled.text-small {
    float: none;
  }
}

.dot {
  height: 15px;
  width: 15px;
  background-color: $white;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.copyrights {
  font-family: "Source Sans Pro", "Regular";
  font-size: 14px;
  background-color: $copyright;
  color: $white;
}

.item .fa,
.item .fas {
  display: unset;
}

.btn-floating {
  border-radius: 50px;
  border: 1px solid $white;
  color: $white;
  line-height: 2.5em;
  width: 2.5em;
  height: 2.5em;
  text-align: center;
  display: inline-block;
  transition: ease-in-out 0.2s;

  &:hover {
    color: $footer;
    background-color: $white;
  }
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label#switcher {
  cursor: pointer;
  text-indent: -9999px;
  width: 35px;
  height: 14px;
  background: #d1d4d6;
  display: inline-block;
  border-radius: 16px;
  position: relative;
  top: 4px;
}

label#switcher:after {
  content: "";
  position: absolute;
  top: -3.5px;
  left: 0;
  width: 20px;
  height: 20px;
  background: $white;
  border-radius: 15px;
  transition: 0.35s;
}

input:checked + label#switcher {
  background: #60c3aa;
}

input:checked + label#switcher:after {
  left: calc(100%);
  transform: translateX(-100%);
}

label#switcher:active:after {
  width: 15px;
}

label#switched {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: #d1d4d6;
  display: inline-block;
  border-radius: 16px;
  border: 1px solid #9aa0a6;
  position: relative;
  top: 4px;
}

label#switched:after {
  content: "";
  position: absolute;
  top: -2.5px;
  left: 0;
  width: 28px;
  height: 28px;
  background: $grey;
  border-radius: 15px;
  transition: 0.35s;
}

input:checked + label#switched {
  background: #60c3aa;
}

input:checked + label#switched:after {
  left: calc(100%);
  transform: translateX(-100%);
  background: $white;
  border: 1px solid $grey;
}

label#switched:active:after {
  width: 25px;
}
