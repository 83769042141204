$primary: #8d021f; // burgundy
$aliceblue: #343a40;
$white: #ffffff;
// google web fonts
$Montserrat: 'Montserrat', sans-serif;
$SourceSanaPro: 'Source Sans Pro', sans-serif;
$tooltip-bg: $white!default;

.bg-gradient-primary {
  background: transparent linear-gradient(270deg, #F1F9FF 0%, #F1F9FFBB 66%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}


