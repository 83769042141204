$font: "Montserrat", sans-serif !important;
$font-text: "Source Sans Pro", sans-serif !important;

// Colors

// ━━━━ General ━━━━

$white:         #ffffff;
$black:         #000000;
$grey:           #788188;
$phplist:       #1B2A36;
$blue:			 #2E73BA;
$green:		  #1B8368;
$orange:       #F47856;
$footer:	      #253746;
$copyright:   #141D24;
$border-radius:    5px;